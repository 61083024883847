import { Action } from '@ngrx/store';
import { ITrigger } from '@cms/types';

export enum ActionTypes {
    SET_TRIGGER = 'SET_TRIGGER',
    SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
    SET_CURRENT_USER = 'SET_CURRENT_USER',
    SET_LANGS = 'SET_LANGS',
    SET_PROVINCE = 'SET_PROVINCE',
    SET_PAGE_LINK = 'SET_PAGE_LINK',
    SET_CURRENT_USER_CLIENT = 'SET_CURRENT_USER_CLIENT',
    INIT_PRODUCT_IN_CART = 'INIT_PRODUCT_IN_CART',
    ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART',
    DELETE_PRODUCT_FROM_CART = 'DELETE_PRODUCT_FROM_CART',
    SET_PRODUCT_TO_CART = 'SET_PRODUCT_TO_CART',
    UPDATE_PRODUCT_FROM_CART = 'UPDATE_PRODUCT_FROM_CART',
    SET_COUPON = 'SET_COUPON',
    CLEAR_CART = 'CLEAR_CART',
    SET_LANG_FORMAT = 'SET_LANG_FORMAT',
    SALE_PAGE_SELECT_PRODUCT = 'SALE_PAGE_SELECT_PRODUCT',
    SET_COUNT_MESSENGER = 'SET_COUNT_MESSENGER',
    UPDATE_PRODUCT_STORE = 'UPDATE_PRODUCT_STORE',
    SET_CART_STEP = 'SET_CART_STEP',
    SET_FREE_ITEM = 'SET_FREE_ITEM',
    SET_POINT_USE = 'SET_POINT_USE'
  }

export class SET_LANG_FORMAT implements Action {
    readonly type = ActionTypes.SET_LANG_FORMAT;
    constructor(public playload: any) { }
}


export class SET_CURRENT_PAGE implements Action{
    readonly type = ActionTypes.SET_CURRENT_PAGE;
    constructor(public playload: any){}
}

export class SET_CURRENT_USER implements Action{
    readonly type = ActionTypes.SET_CURRENT_USER;
    constructor(public playload: any){}
}

export class SET_LANGS implements Action{
    readonly type = ActionTypes.SET_LANGS;
    constructor(public playload: any){}
}

export class SET_PROVINCE implements Action{
    readonly type = ActionTypes.SET_PROVINCE;
    constructor(public playload: any){}
}

export class SET_PAGE_LINK implements Action{
    readonly type = ActionTypes.SET_PAGE_LINK;
    constructor(public playload: any){}
}

export class SET_CURRENT_USER_CLIENT implements Action{
    readonly type = ActionTypes.SET_CURRENT_USER_CLIENT;
    constructor(public playload: any){}
}

export class INIT_PRODUCT_IN_CART implements Action{
    readonly type = ActionTypes.INIT_PRODUCT_IN_CART;
    constructor(){}
}

export class SET_PRODUCT_TO_CART implements Action {
    readonly type = ActionTypes.SET_PRODUCT_TO_CART;
    constructor(public playload: any) { }
}

export class ADD_PRODUCT_TO_CART implements Action{
    readonly type = ActionTypes.ADD_PRODUCT_TO_CART;
    constructor(public playload: any, public qty: Number = 1){}
}

export class DELETE_PRODUCT_FROM_CART implements Action{
    readonly type = ActionTypes.DELETE_PRODUCT_FROM_CART;
    constructor(public playload: any){}
}

export class UPDATE_PRODUCT_FROM_CART implements Action{
    readonly type = ActionTypes.UPDATE_PRODUCT_FROM_CART;
    constructor(public id: any, public qty: number){}
}

export class SET_COUPON implements Action{
    readonly type = ActionTypes.SET_COUPON;
    constructor(public code: string){}
}

export class CLEAR_CART implements Action {
    readonly type = ActionTypes.CLEAR_CART;
    constructor() { }
}


export class SALE_PAGE_SELECT_PRODUCT implements Action {
  readonly type = ActionTypes.SALE_PAGE_SELECT_PRODUCT;
  constructor(public playload: any) { }
}

export class SET_COUNT_MESSENGER implements Action {
  readonly type = ActionTypes.SET_COUNT_MESSENGER;
  constructor(public playload: number) { }
}

export class UPDATE_PRODUCT_STORE implements Action {
    readonly type = ActionTypes.UPDATE_PRODUCT_STORE;
    constructor(public playload: any) { }
}


export class SET_TRIGGER implements Action {
    readonly type = ActionTypes.SET_TRIGGER;
    constructor(public playload: ITrigger) { }
}

export class SET_CART_STEP implements Action {
    readonly type = ActionTypes.SET_CART_STEP;
    constructor(public playload: number) { }
}

// SET_FREE_ITEM
export class SET_FREE_ITEM implements Action {
    readonly type = ActionTypes.SET_FREE_ITEM;
    constructor(public playload: any[]) { }
}


export class SET_POINT_USE implements Action {
    readonly type = ActionTypes.SET_POINT_USE;
    constructor(public playload: number) { }
}






export type All
    = SET_CURRENT_PAGE
    | SET_CURRENT_USER
    | SET_LANGS
    | SET_PROVINCE
    | SET_PAGE_LINK
    | SET_CURRENT_USER_CLIENT
    | ADD_PRODUCT_TO_CART
    | DELETE_PRODUCT_FROM_CART
    | UPDATE_PRODUCT_FROM_CART
    | INIT_PRODUCT_IN_CART
    | SET_PRODUCT_TO_CART
    | SET_COUPON
    | CLEAR_CART
    | SET_LANG_FORMAT
    | SALE_PAGE_SELECT_PRODUCT
    | SET_COUNT_MESSENGER
    | UPDATE_PRODUCT_STORE
    | SET_TRIGGER
    | SET_CART_STEP
    | SET_FREE_ITEM
    | SET_POINT_USE
