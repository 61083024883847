import { Component, OnInit,Input } from '@angular/core';
import { ProductsService } from '@cms/services/products.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { find,cloneDeep } from 'lodash';
@Component({
  selector: 'app-modal-addproduct',
  templateUrl: './modal-addproduct.component.html',
  styleUrls: ['./modal-addproduct.component.css']
})
export class ModalAddproductComponent implements OnInit {
  @Input() data: any;
  constructor(private Service_Product: ProductsService, private modal: NzModalRef) { }
  model: any = {
    lang: "lang1",
    status: "",
    search: "",
    cate_id: "",
    group: "",
    page: 1,
    perpage: 25,
    ids: "",
    orderby: "buy",
    "sort": "desc"
  }
  product:any = [];
  total:any = 0;
  selecteditem:any =[];
  allcategory:any=[];
  limitProduct: number = 0;
  isSelectQty: boolean = true;
  _sortBy: any[] = [
    { id: "3", name: "สินค้าล่าสุด" },
    { id: "2", name: "สินค้าเก่าสุด" },
    { id: "0", name: "สินค้าจาก ก - ฮ" },
    { id: "1", name: "สินค้าจาก ฮ - ก" },
    { id: "4", name: "ราคา ต่ำ - สูง" },
    { id: "5", name: "ราคา สูง - ต่ำ" },
    { id: "buy", name: "สินค้าขายดี" },
    { id: "7", name: "สินค้าแนะนำ" }
  ];
  async ngOnInit() {
    if (localStorage.getItem('orderby')) {
      this.model.orderby = localStorage.getItem('orderby')
    }
    console.log(this.data.details);  
    // alert(1);
    this.allcategory = await this.Service_Product.productMainCategory();
    this.allcategory.unshift({ id: "-1", lang1: "Uncategory", lang2: "Uncategory", lang3: "Uncategory", lang4: "Uncategory" });
    this.allcategory.unshift({ id: "", lang1: "All", lang2: "All", lang3: "All", lang4: "All" });
    
    this.search();
    this.selecteditem = cloneDeep(this.data.details);
    this.limitProduct = this.data.hasOwnProperty('limitProduct') ? this.data.limitProduct : 0;
    this.isSelectQty = this.data.hasOwnProperty('isSelectQty') ? this.data.isSelectQty : true;
  }
  pagechage(page) { 
    this.model.page = page;
    this.search();
  }
  deleteSelected(i){
    this.selecteditem.splice(i,1);
  }
  addProduct(data){
    let chk = find(this.selecteditem, function (o) { return o.id == data.id; });
    if(!chk){
      data.qty = 1;
      if (!this.limitProduct) {
        this.selecteditem.push(data);
      } else {
        if (this.limitProduct === this.selecteditem.length) return;
        this.selecteditem.push(data);
      }     
    }else{
      chk.qty++;
    }

  }
  search(){
    this.Service_Product.searchProduct(this.model).then((res: any) => {
      console.log(res);
      localStorage.setItem('orderby', this.model.orderby);
      this.product = res.data;
      this.total = res.count;
    }).catch(data => {
      console.log(data.error.message);
    });
  }
  ok(){
    this.modal.close(this.selecteditem);
  }

}
