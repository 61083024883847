import { cloneDeep } from 'lodash';
import { ActionTypes, All } from "./action";
import {
  CURRENT_PAGE,
  CURRENT_USER,
  LANGS
} from '../interface/interface';
import { ITrigger } from '@cms/types';

interface AppState {
  CURRENT_PAGE: CURRENT_PAGE
  CURRENT_USER: CURRENT_USER
  LANGS: LANGS[]
  PROVINCE: any[]
  PAGE_LINK: CURRENT_PAGE[]
  CURRENT_USER_CLIENT: any
  CART_STORE: any
  LANG_FORMAT: any
  SALE_PAGE_SELECT_PRODUCT: any
  COUNT_MESSENGER: number
  PRODUCT_STORE: any
  TRIGGER: ITrigger
  FREE_ITEM: any[]
}

const InitialState: AppState = {
  CURRENT_PAGE: undefined,
  CURRENT_USER: {
    cms_version: null,
    create_by: "",
    create_date: "",
    customer_id: null,
    email: "",
    id: null,
    lastname: "",
    name: "",
    picture: "",
    status_use: "",
    tel: "",
    update_by: null,
    update_date: "",
    user_role: "",
    usr_name: ""
  },
  LANGS: [],
  PROVINCE: [],
  PAGE_LINK: [],
  CURRENT_USER_CLIENT: undefined,
  CART_STORE: {
    POINT_USE: 0,
    step: 0,
    details: []
  },
  FREE_ITEM: [],
  LANG_FORMAT: [],
  SALE_PAGE_SELECT_PRODUCT: {},
  COUNT_MESSENGER: 0,
  PRODUCT_STORE: {
    data: []
  },
  TRIGGER: {
    type: "",
    ref: ""
  }
}


export function AppStore(state = InitialState, action: All): AppState {
  switch (action.type) {
    case ActionTypes.SET_TRIGGER:
      state.TRIGGER = {
        ...action.playload
      };
      return state;
    case ActionTypes.SET_CURRENT_PAGE:
      state.CURRENT_PAGE = action.playload;
      return state;
    case ActionTypes.SET_CURRENT_USER:
      state.CURRENT_USER = action.playload;
      return state;
    case ActionTypes.SET_LANGS:
      state.LANGS = action.playload;
      return state;
    case ActionTypes.SET_PROVINCE:
      state.PROVINCE = action.playload;
      return state;
    case ActionTypes.SET_PAGE_LINK:
      state.PAGE_LINK = action.playload;
      return state;
    case ActionTypes.SET_CURRENT_USER_CLIENT:
      state.CURRENT_USER_CLIENT = action.playload;
      return state;
    case ActionTypes.INIT_PRODUCT_IN_CART:
      try {
        var data: any = JSON.parse(localStorage.getItem('CART_STORE'))
        if (data) {
          data['step'] = 0
          state.CART_STORE = {...data}
        }
      } catch (err) {

      }
      return state;
    case ActionTypes.CLEAR_CART:
      state.CART_STORE = {
        step: 0,
        details: []
      }
      localStorage.removeItem('CART_STORE');
      return state;
    case ActionTypes.SET_PRODUCT_TO_CART:
      var obj = action.playload
      state.CART_STORE.details = [...obj];
      localStorage.setItem('CART_STORE', JSON.stringify(state.CART_STORE))
      return state;
    case ActionTypes.ADD_PRODUCT_TO_CART:
      //console.log(action.playload)
      var obj = action.playload
      var qty: any = action.qty
      if(qty  === null || qty === undefined){
        qty = 1;
      }
      if(qty > obj.instock){
        qty = obj.instock
      }
      //console.log(obj, qty)
      var check_product = state.CART_STORE.details.find(x => x.product_id == obj.id)
      if (!check_product) {
        var model = {
          product_id: obj.id,
          price: (obj.price * qty),
          product_price: obj.price,
          feature_img: obj.feature_img,
          title_lang1: obj.title_lang1,
          title_lang2: obj.title_lang2,
          title_lang3: obj.title_lang3,
          title_lang4: obj.title_lang4,
          permalink_lang1: obj.permalink_lang1,
          permalink_lang2: obj.permalink_lang2,
          permalink_lang3: obj.permalink_lang3,
          permalink_lang4: obj.permalink_lang4,
          prop_enable: obj.prop_enable,
          property_info: obj.property_info,
          property_info2: obj.property_info2,
          properties_desc: obj.properties_desc,
          properties_desc2: obj.properties_desc2,
          sku: obj.sku,
          weight: obj.weight,
          cate_id: obj.cate_id,
          qty: qty,
          instock: obj.instock,
          size_height: obj.size_height || 0,
          size_length: obj.size_length || 0,
          size_width: obj.size_width || 0
        }
        state.CART_STORE.details = [...state.CART_STORE.details, { ...model }]
        state.CART_STORE = {...state.CART_STORE}
      } else {
        check_product.qty += qty

        if(check_product.qty > check_product.instock){
          check_product.qty = check_product.instock
        }

        check_product.price = (check_product.product_price * check_product.qty)
        state.CART_STORE = {...state.CART_STORE}
      }
      localStorage.setItem('CART_STORE', JSON.stringify(state.CART_STORE))
      return state;
    case ActionTypes.DELETE_PRODUCT_FROM_CART:
      var id = action.playload
      var check_product: any = state.CART_STORE.details.findIndex(x => x.product_id == id)
      if (check_product !== -1) {
        state.CART_STORE.details.splice(check_product, 1)
        state.CART_STORE.details = [...state.CART_STORE.details]
        localStorage.setItem('CART_STORE', JSON.stringify(state.CART_STORE))
      }
      return state;
    case ActionTypes.UPDATE_PRODUCT_FROM_CART:
      var id = action.id
      var check_product: any = state.CART_STORE.details.find(x => x.product_id == id)
      if (check_product !== -1) {

        check_product.qty = action.qty

        if(check_product.qty > check_product.instock){
          check_product.qty = check_product.instock
        }

        check_product.price = (check_product.product_price * check_product.qty)
        state.CART_STORE.details = [...state.CART_STORE.details]
        localStorage.setItem('CART_STORE', JSON.stringify(state.CART_STORE))
      }
      return state;
    case ActionTypes.SET_COUPON:
			var code = action.code
			state.CART_STORE.coupon = code
      state.CART_STORE = {...state.CART_STORE}
      localStorage.setItem('CART_STORE', JSON.stringify(state.CART_STORE))
      return state;

    case ActionTypes.SET_LANG_FORMAT:
      state.LANG_FORMAT = action.playload;
      return state;

    case ActionTypes.SALE_PAGE_SELECT_PRODUCT:
      state.SALE_PAGE_SELECT_PRODUCT = action.playload;
      return state;
    case ActionTypes.SET_COUNT_MESSENGER:
      state.COUNT_MESSENGER = action.playload;
      return state;
    case ActionTypes.UPDATE_PRODUCT_STORE:
      state.PRODUCT_STORE = { ...state.PRODUCT_STORE,...action.playload };
      return state;
    case ActionTypes.SET_POINT_USE:
      state.CART_STORE.POINT_USE = action.playload;
      return state;
    case ActionTypes.SET_CART_STEP:
      state.CART_STORE.step = action.playload;
      return state;
    // set free item
    case ActionTypes.SET_FREE_ITEM:
      let newFREE_ITEM = cloneDeep(action.playload);
      if(newFREE_ITEM.length === 0){
        state.FREE_ITEM = [];
        return state;
      }
      let old_free_item = cloneDeep(state.FREE_ITEM);
      let delIndex = 0
      for(let obj of  old_free_item){
        let index = newFREE_ITEM.findIndex(x => x.id == obj.id)
        if(index == -1){
          console.log('delIndex',obj)
          old_free_item.splice(delIndex, 1)
        }
        delIndex++
      }
       // update qty and add
       for (let item of newFREE_ITEM) {
        let index = old_free_item.findIndex(x => x.id == item.id)
        if (index != -1) {
          for(let key in item){
            old_free_item[index][key] = item[key]
          }
        }else {
          old_free_item.push(item)
        }
      }
      state.FREE_ITEM = [...old_free_item];
      // console.log('state.FREE_ITEM',state.FREE_ITEM)
      return state;
    default:
      return state;
  }
}
